import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import PolicySEO from "../components/shared/policySEO"
import PolicyContent from "../components/privacy/policyContent"

const PublicDisclosureStatement = () => {

  const { sanityPublicDisclosureStatement } = useStaticQuery(graphql`
  {
    sanityPublicDisclosureStatement {
      policyContent {
        _rawContent
      }
          seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
    }
  }
  `)


  return (
    <div className="subpage-container">
      <PolicySEO seoInformation={sanityPublicDisclosureStatement.seo} />
      <PolicyContent policyContent={sanityPublicDisclosureStatement.policyContent} policyName="Public Disclosure Statement" />
    </div>
  )
}

export default PublicDisclosureStatement
